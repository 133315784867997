import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { useSettings } from '@/app/context/SettingsContext/useSettings';
import React from 'react';

export function ThemeSelector() {
  const { theme, updateTheme } = useSettings();
  const { trackEvent, analyticsInstance } = useAnalytics();

  return (
    <div className="mt-6 flex items-center gap-5">
      <div>
        <button
          className="border-1 cursor-pointer rounded border border-solid border-base-content pb-2 pl-4 pr-4 pt-2"
          style={{ background: '#1f2937', color: 'white' }}
          onClick={() => {
            trackEvent(analyticsInstance, 'settings_click_dark_theme');
            updateTheme('limbiks');
          }}
        >
          Dark Theme
        </button>
        <div
          style={{ visibility: theme === 'limbiks' ? 'visible' : 'hidden' }}
          className="border-b-4 border-primary pt-2"
        ></div>
      </div>
      <div>
        <button
          className="border-1 cursor-pointer rounded border border-solid border-base-content pb-2 pl-4 pr-4 pt-2"
          style={{ background: '#b1c8d3', color: 'black' }}
          onClick={() => {
            trackEvent(analyticsInstance, 'settings_click_light_theme');
            updateTheme('light');
          }}
        >
          Light Theme
        </button>
        <div
          style={{ visibility: theme === 'light' ? 'visible' : 'hidden' }}
          className="border-b-4 border-primary pt-2"
        ></div>
      </div>
    </div>
  );
}

'use client';

import { useState } from 'react';
import { Dialog, DialogPanel, PopoverGroup } from '@headlessui/react';
import {
  ArrowPathIcon,
  Bars3Icon,
  SquaresPlusIcon,
  RectangleStackIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  ClipboardDocumentListIcon,
  AcademicCapIcon,
  LightBulbIcon,
  BoltIcon,
  CloudArrowDownIcon,
  PhotoIcon,
  RocketLaunchIcon,
  QueueListIcon,
  CurrencyDollarIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline';
import LogoTitle from '../../common/logo-title/LogoTitle';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import Link from 'next/link';
import Image from 'next/image';
import { HeaderItemPopout } from './header-item-popout/HeaderItemPopout';
import { MobileItemDisclosure } from './mobile-item-disclosure/MobileItemDisclosure';

import pdfIcon from 'public/pdf.png';

const generators = [
  {
    name: 'PDF to Flashcards',
    description: 'Upload any PDF File',
    href: '/pdf-to-flashcards',
    iconComponent: (
      <Image height={20} width={20} alt={'pdf icon'} src={pdfIcon} />
    )
  },
  {
    name: 'PPTX to Flashcards',
    description: 'Upload PowerPoint Presentations',
    href: '/pptx-to-flashcards',
    iconComponent: (
      <Image
        height={20}
        width={20}
        alt={'powerpoint icon'}
        src="/powerpoint_icon.svg"
      />
    )
  },
  {
    name: 'DOCX to Flashcards',
    description: 'Upload Word Documents',
    href: '/docx-to-flashcards',
    iconComponent: (
      <Image
        height={20}
        width={20}
        alt={'microsoft word icon'}
        src="/word_icon.svg"
      />
    )
  },
  {
    name: 'Image to Flashcards',
    description: 'Upload any image',
    href: '/image-to-flashcards',
    iconComponent: (
      <Image height={20} width={20} alt={'camera icon'} src="/camera.svg" />
    )
  },
  {
    name: 'YouTube to Flashcards',
    description: 'Paste a YouTube link',
    href: '/youtube-to-flashcards',
    iconComponent: (
      <Image
        height={20}
        width={20}
        alt={'youtube icon'}
        src="/youtube_icon.svg"
      />
    )
  },
  {
    name: 'Wikipedia to Flashcards',
    description: 'Search for any article',
    href: '/wikipedia-to-flashcards',
    iconComponent: (
      <Image
        height={20}
        width={20}
        alt={'wikipedia icon'}
        src="/wikipedia_logo.png"
      />
    )
  }
];

const features = [
  {
    name: 'AI Practice Tests',
    description: 'Generate exams with score reports',
    href: '/ai-practice-tests',
    icon: AcademicCapIcon
  },
  {
    name: 'AI Study Guides',
    description: 'Focus on what you need to know',
    href: '/ai-study-guides',
    icon: ClipboardDocumentListIcon
  },
  {
    name: 'AI Image Occlusion',
    description: 'Occlude images from any file',
    href: '/ai-image-occlusion',
    icon: PhotoIcon
  },
  {
    name: 'Spaced Repetition',
    description: 'The fastest way to memorize',
    href: '/spaced-repetition',
    icon: BoltIcon
  },
  {
    name: 'AI Image Occlusion Anki Add-on',
    description: 'Genreate occlusions inside Anki',
    href: '/anki-ai-image-occlusion',
    icon: SquaresPlusIcon
  },
  {
    name: 'Practice Test Generator Anki Add-on',
    description: 'Create practice tests from Anki',
    href: '/anki-practice-tests',
    icon: SquaresPlusIcon
  }
];

const examples = [
  {
    name: 'Flashcards',
    description: 'See examples of generated flashcards',
    href: '#',
    icon: RectangleStackIcon
  },
  {
    name: 'Multiple Choice Questions',
    description: 'See examples of generated questions',
    href: '#',
    icon: QueueListIcon
  },
  {
    name: 'Practice Test',
    description: 'Try out a practice test',
    href: '#',
    icon: AcademicCapIcon
  },
  {
    name: 'Study Guides',
    description: 'See example study guides',
    href: '#',
    icon: ClipboardDocumentListIcon
  },
  {
    name: 'Image Occlusions',
    description: 'See examples of generated occlusions',
    href: '#',
    icon: PhotoIcon
  }
];

const companyLinks = [
  {
    name: 'Pricing',
    description: 'Compare free and pro plans',
    href: '/pricing',
    icon: CurrencyDollarIcon
  },
  // {
  //   name: 'Documentation',
  //   description: 'Everything you need to know',
  //   href: '/documentation',
  //   icon: DocumentDuplicateIcon
  // },
  {
    name: 'About',
    description: 'The origin story',
    href: '/about',
    icon: RocketLaunchIcon
  },
  {
    name: 'Contact',
    description: 'Get in touch',
    href: '/contact',
    icon: EnvelopeIcon
  },
  {
    name: 'FAQ',
    description: 'Frequently asked questions',
    href: '/faq',
    icon: QuestionMarkCircleIcon
  }
  //   {
  //     name: 'Discord',
  //     description: 'Chat with us on Discord',
  //     href: '#',
  //     iconComponent: (
  //       <Image
  //         height={20}
  //         width={20}
  //         alt={'discord icon'}
  //         src="/discord-icon.svg"
  //       />
  //     )
  //   },
  //   {
  //     name: 'Reddit',
  //     description: 'Get updates on the latest features',
  //     href: '#',
  //     iconComponent: (
  //       <Image
  //         height={20}
  //         width={20}
  //         alt={'reddit icon'}
  //         src="/reddit_icon.svg"
  //       />
  //     )
  //   },
  //   {
  //     name: 'Instagram',
  //     description: 'Follow us on Instagram',
  //     href: '#',
  //     iconComponent: (
  //       <Image
  //         height={20}
  //         width={20}
  //         alt={'instagram icon'}
  //         src="/instagram_icon.svg"
  //       />
  //     )
  //   },
  //   {
  //     name: 'Twitter',
  //     description: 'Follow us on X',
  //     href: '#',
  //     iconComponent: (
  //       <Image height={20} width={20} alt={'x icon'} src="/x_icon.svg" />
  //     )
  //   }
];

export default function AnonumousHeader() {
  const { showAuth, user } = useAuth();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const onLoginClick = () => {
    setMobileMenuOpen(false);
    trackEvent(analyticsInstance, 'home_header_click_login');
    showAuth(false);
  };

  const onSignupClick = () => {
    setMobileMenuOpen(false);
    trackEvent(analyticsInstance, 'home_header_click_sign_up');
    showAuth(true);
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="absolute inset-x-0 top-0 z-50 text-base-content">
      <nav
        aria-label="Global"
        className="container mx-auto flex items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex-1">
          <LogoTitle hideLogo={false} onClickRoute={'/'} />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>

        <PopoverGroup className="hidden gap-x-6 lg:flex xl:gap-x-12">
          <HeaderItemPopout title="Generators" items={generators} />
          {/* <Link
            href="/anki-ai-image-occlusion"
            className="text-md flex items-center gap-x-1 leading-6"
          >
            Anki AI Image Occlusion
          </Link> */}
          <HeaderItemPopout title="Features" items={features} />
          {/* <HeaderItemPopout title="Examples" items={examples} /> */}
          <Link
            href="/examples"
            className="text-md flex items-center gap-x-1 leading-6"
          >
            Examples
          </Link>
          <HeaderItemPopout title="Company" items={companyLinks} />
        </PopoverGroup>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <ul className="flex items-center">
            {(!user || user.isAnonymous) && (
              <li className="mr-3">
                <button
                  onClick={onLoginClick}
                  className="hover:text-underline inline-block cursor-pointer px-4 py-2 text-black no-underline hover:text-gray-300 lg:text-base-content"
                >
                  Login
                </button>
              </li>
            )}
            {user && !user.isAnonymous && (
              <li
                onClick={() =>
                  trackEvent(analyticsInstance, 'home_header_click_my_limbiks')
                }
                className="mr-3"
              >
                <div className="hover:text-underline inline-block cursor-pointer px-4 py-2 font-bold text-black no-underline hover:text-gray-300 lg:text-base-content">
                  <Link href="/app">Go to My Limbiks</Link>
                </div>
              </li>
            )}
          </ul>
          {(!user || user.isAnonymous) && (
            <button
              id="navAction"
              onClick={onSignupClick}
              className="focus:shadow-outline mx-auto mt-4 transform rounded-full bg-primary px-8 py-4 font-bold text-primary-content shadow transition duration-300 ease-in-out hover:scale-105 hover:underline focus:outline-none lg:mx-0 lg:mt-0"
            >
              Sign Up
            </button>
          )}
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-[1000]" />
        <DialogPanel className="fixed inset-y-0 right-0 z-[1000] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Limbiks</span>
              <Image
                className="hidden md:flex"
                src="/orange-logo.png"
                width={40}
                height={40}
                alt={'Limbiks logo'}
                style={{ transform: 'scaleX(1)' }}
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <MobileItemDisclosure title="Generators" items={generators} />
                <MobileItemDisclosure title="Features" items={features} />
                {/* <Link
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  href="/anki-ai-image-occlusion"
                >
                  Anki AI Image Occlusion
                </Link> */}
                <Link
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  href="/examples"
                >
                  Examples
                </Link>
                {/* <MobileItemDisclosure title="Examples" items={examples} /> */}
                <MobileItemDisclosure title="Company" items={companyLinks} />

                {/* <Link href="/about">
                  <a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    About
                  </a>
                </Link>
                <Link href="/faq">
                  <a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    FAQ
                  </a>
                </Link> */}
              </div>

              <div className="py-6">
                {(!user || user.isAnonymous) && (
                  <a
                    onClick={onLoginClick}
                    className="-mx-3 block cursor-pointer rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Login
                  </a>
                )}
                {(!user || user.isAnonymous) && (
                  <a
                    onClick={onSignupClick}
                    className="-mx-3 block cursor-pointer rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Sign up
                  </a>
                )}
                {user && !user.isAnonymous && (
                  <Link
                    href={'/app'}
                    onClick={() =>
                      trackEvent(
                        analyticsInstance,
                        'home_header_click_my_limbiks'
                      )
                    }
                    className="-mx-3 block cursor-pointer rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Go to My Limbiks
                  </Link>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}

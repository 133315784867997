import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import Image from 'next/legacy/image';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { useModal } from '@/app/context/ModalContext/useModal';
import { ProFeatureKey } from '../modal/upgrade/Upgrade';

const UploadButton = ({ disabled, onChange }) => {
  const { trackEvent, analyticsInstance } = useAnalytics();
  const uploadInput = useRef(null);

  const { openModal } = useModal();

  const { subscription, isProEligible } = useCustomer();
  const [didTrackUpgrade, setDidTrackUpgrade] = useState(false);

  useEffect(() => {
    if (!subscription.isPro && isProEligible && didTrackUpgrade) {
      trackEvent(analyticsInstance, 'upgrade_visible', {
        upgrade_source: 'generate_upload'
      });
      setDidTrackUpgrade(true);
    }
  }, [subscription, isProEligible]);

  const onClick = () => {
    if (!disabled) {
      uploadInput.current.click();
    }
  };

  const thisOnChange = (event) => {
    const file = event.target.files[0];
    onChange(file.name, file);
    uploadInput.current.value = '';
  };

  return (
    <div>
      <input
        ref={uploadInput}
        accept=".pptx,.docx,.pdf,.txt,.png,.jpg,.jpeg"
        type="file"
        className="invisible absolute"
        multiple={false}
        onInput={thisOnChange}
      />
      <button
        onClick={onClick}
        className={`mb-2 inline-flex w-full items-center justify-center bg-primary ${
          !disabled
            ? 'cursor-pointer duration-300 ease-in-out hover:scale-105 hover:underline'
            : 'opacity-60'
        } transform rounded px-4 py-2 font-bold text-primary-content transition`}
      >
        <svg
          className="mr-2 h-4 w-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z" />
        </svg>
        <span>Click here to upload a file</span>
      </button>

      <div className="mt-2 flex content-center items-center justify-center gap-2 align-middle">
        <div className="text-sm">Supported Files: </div>
        <Image height={20} width={20} alt={'pdf icon'} src="/pdf.png" />
        <Image
          height={20}
          width={20}
          alt={'powerpoint icon'}
          src="/powerpoint_icon.svg"
        />
        <Image
          height={20}
          width={20}
          alt={'microsoft word icon'}
          src="/word_icon.svg"
        />
        <Image height={20} width={20} alt={'camera icon'} src="/camera.svg" />
      </div>
      {subscription.isLoaded && subscription.uploadsRemaining <= 10 && (
        <div className="mt-4 flex content-center items-center justify-center space-x-2 align-middle">
          <div className="flex flex-col items-center gap-1 text-sm text-gray-500 md:flex-row">
            <span>
              {subscription.uploadsRemaining} uploads remaining
              <span>
                {' '}
                until {subscription.uploadResetDate.toLocaleDateString()}
              </span>
            </span>

            {!subscription.isPro && isProEligible && (
              <>
                <span className="hidden md:block">{' - '}</span>
                <span
                  onClick={() => {
                    trackEvent(
                      analyticsInstance,
                      'generate_upload_click_upgrade'
                    );
                    trackEvent(analyticsInstance, 'upgrade_click', {
                      upgrade_source: 'generate_upload'
                    });

                    openModal('upgrade', 'uploads' as ProFeatureKey);
                  }}
                  className="link text-base-content"
                >
                  Upgrade to Pro
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadButton;

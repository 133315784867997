import AnkiConnectAPI from '@/app/api/AnkiConnectAPI/AnkiConnectAPI';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useDeck } from '@/app/context/DeckContext/useDeck';
import { useFeedback } from '@/app/context/FeedbackContext/useFeedback';
import React from 'react';
import Loader from '../../loader/Loader';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';

export default function AnkiError({ onClose }) {
  const { selectedDeck, selectedDeckData, downloadAnkiPackage } = useDeck();
  const { submitFeedback } = useFeedback();
  const { user } = useAuth();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const [isBuilding, setIsBuilding] = React.useState(false);

  const onDownloadImageOcclusion = async () => {
    try {
      trackEvent(analyticsInstance, 'anki_modal_download_image_occlusion', {
        deck_id: selectedDeck.id
      });

      setIsBuilding(true);

      await downloadAnkiPackage();

      submitFeedback('Download Anki Success - ' + user.email);
    } catch (e) {
      trackEvent(analyticsInstance, 'anki_modal_download_failed', {
        error: e.toString(),
        deck_id: selectedDeck.id
      });

      submitFeedback(
        'Download Anki Failed - ' + user.email + ' - ' + e.toString()
      );
    }
    setIsBuilding(false);
  };

  const onDownload = () => {
    trackEvent(analyticsInstance, 'anki_modal_download', {
      deck_id: selectedDeck.id
    });

    submitFeedback(
      'Anki Download - ' +
        selectedDeck.fileType +
        ' - ' +
        selectedDeck.cardCount +
        ' cards - ' +
        user.email
    );
    AnkiConnectAPI.downloadAnkiPackage(selectedDeck.name, selectedDeckData);
  };

  return (
    <div>
      <div className="w-full text-center text-lg font-bold">
        Anki Sync Failed
      </div>
      <div className="mt-2 w-full text-center">
        Limbiks failed to sync to Anki using AnkiConnect.
      </div>

      <div className="mt-2 w-full text-center">
        Please follow our recommendations below to make sure everything is setup
        properly. <br />
        Alternatively, click the button below to download an .akpg file that you
        can import into Anki.
      </div>
      <div className="mb-3 mt-5 w-full text-center text-lg font-bold">
        {!isBuilding && (
          <button
            onClick={
              selectedDeck.isImageOcclusion
                ? onDownloadImageOcclusion
                : onDownload
            }
            className="btn btn-primary"
          >
            {' '}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="-mt-1 mr-3 inline-block w-5 fill-primary-content text-primary-content"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z" />
              </svg>
            </span>{' '}
            Download {selectedDeck.name}.akpg
          </button>
        )}
        {isBuilding && (
          <div className="flex items-center justify-center">
            <Loader />
            <div>Building Anki Package...</div>
          </div>
        )}
      </div>
      <div className="mt-5 w-full text-center text-lg font-bold">
        AnkiConnect Add-on Missing
      </div>
      <div className="mt-2 w-full text-center">
        Make sure the{' '}
        <a
          onClick={() => {
            trackEvent(analyticsInstance, 'anki_modal_click_ankiconnect_link', {
              deck_id: selectedDeck.id
            });
          }}
          target="_blank"
          href="https://ankiweb.net/shared/info/2055492159"
          className="link place-items-center text-center font-bold text-secondary"
        >
          AnkiConnect
        </a>{' '}
        add-on is installed and you have restarted Anki.
      </div>
      <div className="mt-5 w-full text-center text-lg font-bold">
        Limbiks not added to AnkiConnect Configuration
      </div>
      <div className="mt-2 w-full text-center">
        Add Limbiks to the webCorsOriginList in the AnkiConnect Add-on
        configuration (see screenshot)
      </div>

      <div className="mt-2 w-full text-center">
        You can update the webCorsOriginList by clicking
        <br /> {'Tools > Add-ons > AnkiConnect'}
      </div>
      <div className="mt-5 w-full text-center">
        <img src="/AnkiConnectSnip.jpg" />
      </div>
      <div className="mt-5 w-full text-center text-lg font-bold">
        App Nap blocking Anki on Mac
      </div>
      <div className="mt-2 w-full text-center">
        Starting with Mac OS X Mavericks, a feature named App Nap has been
        introduced to the operating system. This feature causes certain
        applications which are open (but not visible) to be placed in a
        suspended state.
      </div>
      <div className="mt-2 w-full text-center">
        See AnkiConnect{' '}
        <a
          target="_blank"
          onClick={() => {
            trackEvent(analyticsInstance, 'anki_modal_click_ankiconnect_docs', {
              deck_id: selectedDeck.id
            });
          }}
          href="https://foosoft.net/projects/anki-connect/"
          className="link place-items-center text-center font-bold text-secondary"
        >
          documentation
        </a>{' '}
        to disable App Nap.
      </div>
      <div className="mt-5 w-full text-center text-lg font-bold">
        Safari Browser Failing
      </div>
      <div className="mt-2 w-full text-center">
        Some users have reported issues with Safari. Try using Chrome.
      </div>
      <div className="mt-5 w-full text-center text-lg font-bold">
        Ad Blockers disabling connection
      </div>
      <div className="mt-2 w-full text-center">
        Ad Blockers and similar browser extensions can prevent Limbiks from
        being able to communicate with Anki. Try disabling these browser
        extensions when using Limbiks.
      </div>
      <div className="mt-5 w-full text-center text-lg font-bold">
        Still not working?
      </div>
      <div className="mt-2 w-full text-center">
        Please feel free to reach out to{' '}
        <a
          target="_blank"
          onClick={() => {
            trackEvent(analyticsInstance, 'anki_modal_click_support', {
              deck_id: selectedDeck.id
            });
          }}
          href="mailto:support@limbiks.com"
          className="link place-items-center text-center font-bold text-secondary"
        >
          support@limbiks.com
        </a>{' '}
        if AnkiConnect is still not working after following these
        troubleshooting steps.
      </div>
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
      </div>
    </div>
  );
}

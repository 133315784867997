import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useFeedback } from '@/app/context/FeedbackContext/useFeedback';
import React, { useState } from 'react';

export default function Feedback({ onClose }) {
  const feedback = useFeedback();

  const { user } = useAuth();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');

  const { trackEvent, analyticsInstance } = useAnalytics();

  const submitFeedback = async () => {
    trackEvent(analyticsInstance, 'feedback', {
      message: feedbackText
    });

    feedback.submitFeedback(
      'Actual Feedback - ' + user.email + ' - ' + feedbackText
    );
    setFeedbackText('');
    setFeedbackSubmitted(true);
  };

  return (
    <>
      {feedbackSubmitted && (
        <>
          <div className="mb-8 mt-8 w-full text-center text-lg font-bold">
            Thank you for your feedback!
          </div>
        </>
      )}
      {!feedbackSubmitted && (
        <>
          <div className="w-full text-center text-lg font-bold">
            We need your feedback!
          </div>
          <div className="mt-2 w-full text-center">
            At Limbiks, our #1 priority is to make studying simpler and more
            efficient for every student. If you have any issues, feature
            requests, or other feedback, we would love to hear from you.
          </div>
          <div className="form-control mt-4">
            <textarea
              onChange={(e) => setFeedbackText(e.currentTarget.value)}
              value={feedbackText}
              className="textarea textarea-bordered h-24 border-gray-400 bg-white text-black"
              placeholder="Type your feeback here"
            ></textarea>
          </div>
        </>
      )}
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
        {!feedbackSubmitted && (
          <div>
            <label
              htmlFor="my-modal-2"
              onClick={submitFeedback}
              className="btn bg-primary outline-none"
            >
              Submit
            </label>
          </div>
        )}
      </div>
    </>
  );
}

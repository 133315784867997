import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AnonymousPreview from '@/components/common/anonymous-preview/AnonymousPreview';
import WavyDividerArtwork from '@/artwork/WavyDividerArtwork';
import FlippedWavyDividerArtwork from '@/artwork/FlippedWavyDividerArtwork';
import { PurchasingOptions } from './purchasing-options/PurchasingOptions';
import MarketingContent from './marketing-content/MarketingContent';
import { PageSEO } from '@/components/common/blog/elements/SEO';
import PageLayout from '../common/page-layout/PageLayout';
import { meta } from './models';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

export default function HomePage() {
  return (
    <PageLayout>
      <PageSEO title={meta.title} description={meta.description} />

      <div className="relative mb-8 bg-gray-900 md:isolate md:overflow-x-hidden">
        <div className="hidden md:block">
          <svg
            aria-hidden="true"
            className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
              width="100%"
              height="100%"
              strokeWidth={0}
            />
          </svg>

          <div
            aria-hidden="true"
            className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] md:left-48 md:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
          >
            <div
              style={{
                clipPath:
                  'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)'
              }}
              className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            />
          </div>
        </div>

        <div className="mx-auto flex max-w-7xl flex-col px-6 py-6 pb-24 pt-16 sm:pb-24 md:mb-12 md:flex-row md:py-12 md:pt-40 lg:mb-6 lg:flex lg:px-8 lg:py-12 lg:pt-40">
          <div className="mx-auto max-w-2xl flex-1 flex-shrink-0 text-center md:text-left lg:mx-0 lg:max-w-xl lg:pt-8">
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              AI Flashcard Generator
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Upload PDFs, presentations, notes, images, and more. Limbiks
              generates a comprehensive deck of flashcards in seconds. Master
              your flashcards with our powerful study tools.
            </p>
          </div>
          <div className="flex min-h-[380px] justify-center py-6 pt-20 text-center md:w-3/5 md:min-w-[446px] md:py-8 lg:py-12">
            <div style={{ zIndex: 500 }} className=" w-full lg:w-3/5">
              <AnonymousPreview />
            </div>
          </div>
        </div>
      </div>
      <div className="relative -mt-12 bg-gray-900 lg:-mt-24">
        <WavyDividerArtwork />
      </div>
      <MarketingContent />
      <section className="bg-gray-50">
        <PurchasingOptions />
      </section>
      <FlippedWavyDividerArtwork />
    </PageLayout>
  );
}

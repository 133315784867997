import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import CopyIcon from '@/app/icons/CopyIcon';
import RotateIcon from '@/app/icons/RotateIcon';
import { copyToClipboard } from '@/app/utils/stringUtils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import Loader from '../../loader/Loader';

export default function Help({ onClose }) {
  const { isPro, isProEligible } = useCustomer();
  const { accessKey, rotateAccessKey, user } = useAuth();

  return (
    <>
      <div className="w-full text-center text-lg font-bold">
        Welcome to Limbiks!
      </div>
      <div className="mt-4 w-full text-center">
        Copy your Limbiks Key to securely connect Anki Add-ons to your Limbiks
        account.
      </div>
      <div className="flex flex-col items-center text-lg">
        {!(accessKey && user) && <Loader />}
        {accessKey && user && (
          <div className="mt-6 flex gap-2 rounded-lg bg-base-100 p-4 font-bold text-base-content">
            {accessKey}
            <button
              onClick={() => copyToClipboard(accessKey)}
              className="tooltip scale-90 cursor-pointer"
              data-tip={'Copy to clipboard.'}
            >
              <CopyIcon />
            </button>
            <button
              onClick={rotateAccessKey}
              className="tooltip scale-90 cursor-pointer"
              data-tip={
                'Click here to generate a new Limbiks Key.  Your existing Limbiks Key will no longer be usable.'
              }
            >
              <RotateIcon />
            </button>
          </div>
        )}
      </div>
      <div className="mb-4 mt-8 rounded-xl bg-gray-200 p-4 shadow">
        <div className="link w-full text-center text-lg font-bold text-primary">
          <a href="https://limbiks.com/anki-ai-image-occlusion" target="_blank">
            AI Image Occlusion Anki Add-on
          </a>
        </div>
        <div className="mt-4 text-center text-sm">
          Generate all of your image occlusions in one click
        </div>
        {isPro && (
          <div className="mt-4 w-full text-center">
            As a pro user you can generate up to 500 AI Image Occlusions per
            month.
          </div>
        )}
        {!isPro && (
          <div className="mt-4 w-full text-center">
            Free Users: 25 Generations / month
          </div>
        )}
        {!isPro && isProEligible && (
          <div className="mt-4 w-full text-center">
            Pro Users: 500 Generations / month
            {/* <div className="mt-4">
              <b>
                {' '}
                <span className="link text-primary">
                  <Link href={'/app/account?interval=month&source=key'}>
                    Upgrade to Limbiks Pro
                  </Link>
                </span>
              </b>{' '}
            </div> */}
          </div>
        )}
      </div>
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
      </div>
    </>
  );
}

import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import { CheckIcon } from '@heroicons/react/24/solid';
import Loader from '../../loader/Loader';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export type ProFeatureKey = keyof typeof proFeatures;

export const proFeatures = {
  uploads: '100 uploads / month',
  pagesAndSlides: '200 pages or slides / upload',
  studyGuides: 'AI Generated Study Guides',
  multipleChoice: 'Unlimited multiple choice questions',
  practiceTests: 'Unlimited Practice Tests',
  practiceTestQuestions: '100 questions / practice test',
  hintsAndExplanations: 'Hints and Explanations',
  customInstructions: 'Generate with custom instructions',
  learningObjectives: 'Add learning objectives',
  more: 'and more...'
};

export const defaultFeatures: Array<keyof typeof proFeatures> = [
  'uploads',
  'pagesAndSlides',
  'studyGuides',
  'multipleChoice',
  'hintsAndExplanations'
];

export const practiceTestFeatures: Array<keyof typeof proFeatures> = [
  'uploads',
  'pagesAndSlides',
  'customInstructions',
  'learningObjectives',
  'practiceTests',
  'studyGuides',
  'multipleChoice'
];

export const uploadFeatures: Array<keyof typeof proFeatures> = [
  'uploads',
  'pagesAndSlides',
  'practiceTests',
  'practiceTestQuestions',
  'studyGuides'
];

interface IProps {
  target: keyof typeof proFeatures;
  onClose: () => void;
}

export default function UpdgradeModal(props: IProps) {
  const { country, createCheckoutSession, isCreatingCheckoutSession } =
    useCustomer();

  const { trackEvent, analyticsInstance } = useAnalytics();

  let displayFeatureKeys = defaultFeatures;

  if (!displayFeatureKeys.includes(props.target)) {
    if (practiceTestFeatures.includes(props.target)) {
      displayFeatureKeys = practiceTestFeatures;
    } else if (uploadFeatures.includes(props.target)) {
      displayFeatureKeys = uploadFeatures;
    }
  }

  displayFeatureKeys = [
    props.target,
    ...displayFeatureKeys.filter((k) => k !== props.target),
    'more'
  ];

  const displayFeatures = displayFeatureKeys.map((key) => proFeatures[key]);

  const onClose = () => {
    trackEvent(analyticsInstance, 'upgrade_modal_click_no_thanks', {
      target: props.target
    });

    props.onClose();
  };

  if (isCreatingCheckoutSession) {
    return (
      <div className="flex w-full flex-col items-center gap-6">
        <div>Launching Secure Checkout...</div>
        <Loader />
      </div>
    );
  }

  return (
    <div
      key={'123'}
      className="flex flex-col justify-between rounded-3xl text-black"
    >
      <div>
        <h3
          id={'123'}
          className="text-base font-semibold leading-7 text-gray-600"
        >
          Limbiks Pro
        </h3>
        <div className="mt-4 flex items-baseline gap-x-2">
          <span className="text-5xl font-bold tracking-tight text-gray-700">
            {country.currencySymbol}
            {country.monthlyRate}
            {country.currencySuffix}
          </span>
          <span className="text-base font-semibold leading-7 text-gray-600">
            /
          </span>
          <span className="text-base font-semibold leading-7 text-gray-600">
            month
          </span>
        </div>
        <p className="mt-6 text-base leading-7 text-gray-500">
          Unlock more uploads, more pages and slides per upload, and full access
          to every Limbiks feature.
        </p>
        <ul
          role="list"
          className="mt-6 space-y-2 text-sm leading-6 text-gray-600"
        >
          {displayFeatures.map((feature, index) => (
            <li key={feature} className="flex gap-x-3 text-black">
              <CheckIcon
                aria-hidden="true"
                className="h-6 w-5 flex-none text-primary"
              />
              {index === 0 ? <b>{feature}</b> : feature}
            </li>
          ))}
          <li className="flex gap-x-1 pt-3 text-black">
            <span>
              See the full breakdown of
              <span className="pl-1">
                <a href="/pricing" className="text-primary underline">
                  pricing and features
                </a>
              </span>
            </span>
          </li>
        </ul>
      </div>
      <>
        <button
          onClick={() => {
            trackEvent(analyticsInstance, 'upgrade_modal_click_subscribe', {
              target: props.target
            });
            createCheckoutSession(true);
          }}
          aria-describedby={'!23'}
          className="mt-8 block rounded-md bg-primary px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline hover:opacity-80 focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          Subscribe
        </button>
        <button onClick={onClose} className="mt-4 text-center text-sm">
          No thanks
        </button>
      </>
    </div>
  );
}
